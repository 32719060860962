<template>
	<div class="navmb">
		<div class="flex flexa">
			<el-icon color="#FF6A00" size="20" class="astyle bgshadow" @click="$router.back()">
				<Back />
			</el-icon> &nbsp; &nbsp;
			<span class="">创建Bug</span>
		</div>
	</div>
	<p class="h20"></p>
	<div class="m-boxb2">
		<el-form :model="form" ref="submitForm" label-width="auto" :rules="rules">
			<div class="content">
				<div class="col-l">
					<div class="m-editbox">
						<div class="gp">
							<div class="type"><em>*</em>Bug标题</div>
							<div class="right">
								<el-form-item prop="bugTitle">
									<el-input v-model="form.bugTitle" placeholder="请输入Bug的标题">
									</el-input>
								</el-form-item>
							</div>


						</div>
						<div class="gp">
							<div class="type"><em>*</em>请输入Bug描述</div>
							<div class="right">
								<el-form-item prop="bugContent">
									<!-- <Toolbar style="border-bottom: 1px solid #dfe2e8" :editor="editorRef"
												:mode="mode" />
											<Editor style="height: 550px;width: 100%; overflow-y: hidden" v-model="form.bugContent"
												:defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" /> -->
									<div class="padr10 padt4 flex1" style="z-index: 9999;">
										<!-- <v-md-editor  @upload-image="handleUploadImage" mode="editable" :disabled-menus="[]" v-model="form.bugContent" width="400px" height="400px"></v-md-editor> -->
										<Toolbar style="width: 100%;border: 1px solid #dfe2e8;z-index: 1000" :editor="editorRef"
													:mode="mode" />
										<Editor style="margin-top: -1px;z-index: 1000;height: 550px; width: 100%;border: 1px solid #dfe2e8; overflow-y: hidden" v-model="form.bugContent"
											:defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
									</div>
									<!-- <v-md-editor  @upload-image="handleUploadImage" mode="editable" :disabled-menus="[]" v-model="form.bugContent" @html="htmlc" width="400px" height="400px"></v-md-editor> -->

									<!-- <div class="box">
										<div class="font14"
											style="width: 100%;box-sizing: border-box; z-index: 500;border: 1px solid #dfe2e8">
											<kindeditor :content="form.bugContent" width="700px" height="400px" @base64Html="base64Flie($event, 'form','content')"
			id="kind2" @on-content-change="onContentChange($event, 'form','content')"></kindeditor> -->
											<!-- <Toolbar style="border-bottom: 1px solid #dfe2e8" :editor="editorRef"
												:mode="mode" />
											<Editor style="height: 550px; overflow-y: hidden" v-model="form.bugContent"
												:defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />

										</div>
									</div> -->
								</el-form-item>
							</div>
						</div>
					</div>
				</div>
				<div class="col-r">
					<div class="m-editbox">
						<div class="gp">
							<div class="type"><em>*</em>是否关联SR</div>
							<div class="right">
								<div class="m-radsb1">
									<el-radio-group v-model="form.isSr">
										<el-radio :value="2">是</el-radio>
										<el-radio :value="1">否</el-radio>
									</el-radio-group>
								</div>
								<br />
								<el-form-item prop="outTradeNo" v-if="form.isSr==2">
									<div class="w300">
										<el-input v-model="form.outTradeNo" placeholder="输入关联的工单编号">
										</el-input>
									</div>
								</el-form-item>
							</div>
						</div>
						<div class="gp">
							<div class="type"><em>*</em>选择项目</div>
							<el-form-item prop="productId">
								<div class="right w255">
									<el-select v-model="form.productId" placeholder="请选择项目" @change="vselect_module">
										<el-option v-for="item in vselect.product.options" :key="item.value"
											:label="item.workspaceName" :value="item.id" />
									</el-select>
								</div>
							</el-form-item>
						</div>
						<div class="gp">
							<div class="type"><em>*</em>选择模块</div>
							<el-form-item prop="productModuleId">
								<div class="right w255">
									<el-select v-model="form.productModuleId" placeholder="请选择模块">
										<el-option v-for="item in moduleMapList" :key="item.id" :label="item.moduleName"
											:value="item.id" />
									</el-select>
								</div>
							</el-form-item>
						</div>
						<div class="gp">
							<div class="type"><em>*</em>Bug等级</div>
							<div class="right">
								<el-form-item prop="priority">
									<div class="m-radsb1">
										<el-radio-group v-model="form.priority">
											<el-radio :value="item.value" v-for="(item, index) in priorityList"
												:key="index">{{ item.label }}</el-radio>
										</el-radio-group>
									</div>
								</el-form-item>
							</div>
						</div>
						<div class="gp">
							<div class="type"><em>*</em>发现版本</div>
							<div class="right">
								<el-form-item>
									<div class="m-radsb1">
										<el-input v-model="form.version" placeholder="请输入发现版本" class="w300">
										</el-input>
									</div>
								</el-form-item>
							</div>
						</div>
						<div class="gp">
							<div class="type">问题附件</div>
							<div class="right">
								<ul class="ul-filels" v-if="fileList.attachmentPath.length > 0">
									<li v-for="(item, index) in fileList.attachmentPath" :key="index">
										<div class="con">
											<div class="name">{{ item.name }} {{ item.percentage }}%</div>
											<div class="del" @click="() => {
												fileList.attachmentPath.splice(index, 1)
												form['attachmentPath'] = fileList.attachmentPath.map(item => item.img).filter(item => item).join(',')
											}">删除</div>
										</div>
									</li>
								</ul>
								<el-form-item prop="attachmentPath">
									<div class="upbtn">
										<el-upload
											v-model:file-list="fileList.attachmentPath"
											:ref="el => fileList['instance_attachmentPath'] = el"
											:headers="{ token: $store.state.token }"
											:data="{
												type: 5
											}"
											action="/api/user/upload/ossUploadList"
											class="upload-demo"
											multiple
											:limit="3"
											:show-file-list="false"
											name="multipartFile"
											:on-success="(resp, file, files) => {
												if (resp.status == 200) {
													file.img = utils.Decrypt(resp.data).path;
													form['attachmentPath'] = files.map(item => item.img).filter(item => item).join(',')
												}
											}">
											<div class="btn">
												<el-icon>
													<Upload />
												</el-icon>添加附件
											</div>
										</el-upload>
									</div>
									<div class="tips">
										支持.png、.jpg、.jpeg、.txt、.rar、.doc、.xls、.xlsx、.zip格式，最大不超过50M
									</div>
								</el-form-item>
							</div>
						</div>
						<div class="gp">
							<div class="type">附件注释</div>
							<div class="right">
								<el-form-item prop="attachmentDescription">
									<el-input :rows="4" v-model="form.attachmentDescription" type="textarea"
										placeholder="请输入添加附件的注释">
									</el-input>
								</el-form-item>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-form>
		<div class="foot-fix">
			<div class="btn" @click="submit">确认创建</div>
			<div class="btn btn1" @click="$router.back()">取消</div>
		</div>
	</div>
</template>
<script>
// import { msgDetail, bindingAcount } from "@/utils/api1.js"
import { shallowRef } from 'vue'
import request from '../../utils/request.js'
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
//图标
import { Back, Upload } from '@element-plus/icons-vue'

export default {
	components: {
		Back, Upload, 
		Editor, Toolbar
	},
	computed: {
		priorityList() {
			return [{
				label: '致命',
				value: 1
			}, {
				label: '严重',
				value: 2
			}, {
				label: '一般',
				value: 3
			}, {
				label: '提示',
				value: 4
			}, {
				label: '建议',
				value: 5
			}]
		}
	},
	data() {
		return {
			form: {},
			moduleMapList:[],
			rules: {
				bugTitle: [{ required: true, message: '请输入Bug标题', trigger: 'blur' }],
				bugContent: [{ required: true, message: '请输入Bug描述', trigger: 'blur' }],
				outTradeNo: [{ required: true, message: '请输入工单编号', trigger: 'blur' }],
				productId: [{ required: true, message: '请选择项目', trigger: 'change' }],
				productModuleId: [{ required: true, message: '请选择模块', trigger: 'change' }],
				priority: [{ required: true, message: '请选择等级', trigger: 'change' }],
			},
			fileList: {
				instance_attachmentPath: null,
				attachmentPath: [],
			},
			vselect: {
				product: {
					loading: false,
					options: []
				},
				module: {
					loading: false,
					map_options: new Map()
				},
			},
			mode: 'default',
			editorRef: shallowRef(),
			valueHtml: '', //富文本编辑器内容
			editorConfig: { placeholder: '请输入内容...' },

			input: '',
			param3: {
				problemDescription: ''
			},
			radio: 1,
		}
	},
	mounted() {
		this.vselect_product()
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			const state = history.state
			console.log(state, 'state')
			if(state.id){
				vm.getInnfo(state.id)
			}
		})
	},
	beforeUnmount() {
		// this.editorRef.destroy()
	},
	methods: {
		htmlc(...args) {
			console.log(args,' arsg---	')
		},
		handleUploadImage(event, insertImage, files){
			console.log('图片',files)
			var formData = new FormData();
    		formData.append('multipartFile', files[0]);
    		formData.append('type', 5);
			this.$api.base.ossUploadList(formData).then(res=>{
				insertImage({
					url:res.path,
					desc: files[0].name,
					width: '100px',
					height: 'auto',
				});
			})
		},
		async getInnfo(id){
			this.form = await this.$api.bug.bugdetails({id})
			this.vselect_module(this.form.productId,this.form.productModuleId)
		},
		async vselect_product() {
			try {
				this.vselect.product.loading = true
				const { records, total } = await this.$api.bug.engBugFeedbackGetTapdWorkspaceList({
					pageNumber: 1,
					pageSize: 1500
				})
				console.log(records, 'recV')
				this.vselect.product.options = records
				this.vselect.product.loading = false
			} catch (e) {
				this.vselect.product.loading = false
				this.vselect.product.options = []
			}
		},
		async vselect_module(id,productModuleId) {
			try {
				this.vselect.module.loading = true
				this.form.productModuleId = productModuleId||undefined
				let {records} = await this.$api.bug.engBugFeedbackGetTapdModuleList({
					id,
					pageNumber: 1,
					pageSize: 2000
				})
				this.moduleMapList = records
				this.vselect.module.map_options.set(
					id,
					this.vselect.module.map_options.get(id) ?? (records)
				)
				this.vselect.module.loading = false
			} catch (e) {
				this.vselect.module.loading = false
				this.vselect.module.options = []
			}
		},
		async submit() {
			try {
				this.sumbitLoading = this.fullscreenLoading = true
				await this.$refs['submitForm'].validate()
				const result = await this.$api.bug.newBugFeedback({
					id: this.form.id||'',
					isSr: this.form.isSr,
					outTradeNo: this.form.outTradeNo,
					version: this.form.version,
					productId: this.form.productId,
					productModuleId: this.form.productModuleId,
					priority: this.form.priority,
					attachmentPath: this.form.attachmentPath,
					attachmentDescription: this.form.attachmentDescription,
				}, {
					bugContent: this.form.bugContent,
					bugTitle: this.form.bugTitle,
				})
				if (result) {
					this.$message.success('操作成功')
					this.$router.back()
				}
			} catch (e) {
				return console.log(e)
			} finally {
				this.sumbitLoading = this.fullscreenLoading = false
			}
		},
		handleCreated(editor) {
			this.editorRef = editor // 记录 editor 实例，重要！
			editor.getConfig().MENU_CONF['uploadImage'] = {
				server: '/api/user/upload/ossUploadList',
				fieldName: 'multipartFile',
				allowedFileTypes: ['image/*'],
				withCredentials: true,
				headers: {
					token: this.$store.state.token,
				},
				meta: {
					type: '5',
				},
				onSuccess: (file, res) => {
					console.log(`${file.name} 上传成功`, res)
				},
				onFailed(file, res) {
					console.log(`${file.name} 上传失败`, res)
				},
				customInsert: (res, insertFn) => {
					insertFn(this.utils.Decrypt(res.data).path, '')
				},
			}
			editor.getConfig().MENU_CONF['uploadVideo'] = {
				server: '/api/engineer/upload/ossUploadList',
				fieldName: 'multipartFile',
				allowedFileTypes: ['video/*'],
				withCredentials: true,
				headers: {
					token: this.$store.state.token,
				},
				meta: {
					type: '5',
				},
				onSuccess: (file, res) => {
					console.log(`${file.name} 上传成功`, res)
				},
				onFailed(file, res) {
					console.log(`${file.name} 上传失败`, res)
				},
				customInsert: (res, insertFn) => {
					insertFn(this.utils.Decrypt(res.data).path, '')
				},
			}
		},
		base64Flie(base64, key) {
			// console.log(base64,key)
			// this.param3[key] += "<img  src='"+base64+"' target='_blank' />"
			let pic = new FormData();
			pic.append('file', base64);
			// console.log(pic)

			addAttachment(pic, 0).then(res => {
				console.log(res);
				if (res) {
					this.param3[key] += "<a style='color:#337eff;' href='" + res.downloadPath + "' target='_blank' >" + res.lookPath + "</a>"
				}
			})
		},

		onContentChange(val, key) {
			// console.log(val,key)
			this.param3[key] = val;
			// console.log(this[key])
			this.onHtmlChange(val)
			// console.log(this.param3)
		},

		// 富文本的方法改变返回数据
		onHtmlChange(val) {
			let content = val;
			let imgReg = /<img.*?(?:>|\/>)/gi // 匹配图片中的img标签
			let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
			let arr = content.match(imgReg)  // 筛选出所有的img
			let srcArr = []
			if (arr != null) {
				this.loading = true;

				for (let i = 0; i < arr.length; i++) {
					let src = arr[i].match(srcReg)
					// 获取图片地址判断是否是base64格式
					if (src[1].indexOf('base64') == -1) {
						this.loading = false;
						this.param3.problemDescription = content.replace(/<\/?(img)[^>]*>/gi, "");

						return
					} else {
						srcArr.push(src[1])
					}
				}
				let formData = new FormData()
				srcArr.forEach(item => {
					// 将base64转换成文件流
					formData.append('file', this.base64toFile(item))

					/* addAttachment(formData, 0).then(res => {
					  console.log(res);
					  if (res) {
						this.param3.problemDescription = content.replace(/<\/?(img)[^>]*>/gi, "<a style='color:#337eff;' href='" + res.downloadPath + "' target='_blank' >" + res.lookPath + "</a>");
		  
						// console.log(this.param3.problemDescription)
		  
						this.loading = false;
					  } else {
						this.loading = false;
					  }
		  
					})
		   */
				})
			}

		},

		base64toFile(dataurl) {

			let filename = 'file'
			let arr = dataurl.split(',')
			let mime = arr[0].match(/:(.*?);/)[1]
			let suffix = mime.split('/')[1]
			let bstr = atob(arr[1])
			let n = bstr.length
			let u8arr = new Uint8Array(n)
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n)
			}
			return new File([u8arr], `${filename}.${suffix}`, {
				type: mime
			})
		},


	}
}
</script>
<style lang="scss" scoped>
.m-boxb2 {
	background-color: #fff;
	padding: 30px 40px 20px 0;
	box-sizing: border-box;
	height: calc(100vh - 220px);
	overflow-y: scroll;

	.col-l {
		width: 53%;
		float: left;
		border-right: 1px solid #e8e9eb;
		padding-right: 60px;
	}

	.col-r {
		overflow: hidden;
	}

	.content {
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
}

.foot-fix {
	position: absolute;
	bottom: 0;
	left: 240px;
	right: 0;
	height: 79px;
	background: #ffffff;
	box-shadow: 0px -1px 4px 0px #ebebeb;
	display: flex;
	align-items: center;
	padding-left: 130px;
	transition: 0.3s;

	.btn {
		cursor: pointer;
		font-size: 17px;
		color: #fff;
		margin-right: 12px;
		background-color: #ff6a00;
		height: 40px;
		line-height: 40px;
		border: 1px solid #ff6a00;
		padding: 0 10px;
		min-width: 128px;
		text-align: center;
		transition: .4s;

		&.btn1 {
			background-color: #fff;
			color: #ff6a00;
		}

		&:hover {
			opacity: .8;
		}
	}
}

.navpl {
	.foot-fix {
		left: 63px;
	}
}

.m-radsb1 {
	height: 32px;
}

.m-editbox {
	.gp {
		overflow: hidden;
		margin-bottom: calc(30px - 18px);

		&:last-child {
			margin-bottom: 0;
		}
	}

	.type {
		float: left;
		text-align: right;
		width: 120px;
		padding-left: 5px;
		font-size: 14px;
		color: #8f8f8f;
		line-height: 32px;

		em {
			font-style: normal;
			color: #e1504a;
		}
	}

	.right {
		overflow: hidden;
		padding-left: 20px;
	}

	.tips {
		font-size: 14px;
		color: #8f8f8f;
	}
}

.upbtn {
	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 108px;
		height: 36px;
		background: #ffffff;
		border: 1px solid #dddddd;
		font-size: 14px;
		color: #ff6a00;
		transition: .4s;
		margin-bottom: 8px;

		.el-icon {
			margin-right: 10px;
		}

		&:hover {
			border-color: #ff6a00;
			background-color: #ff6a00;
			color: #fff;
		}
	}
}

.ul-filels {
	margin-bottom: 17px;

	.con {
		display: flex;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;
		background-color: #f7f7f7;
		padding: 0 20px;
		font-size: 14px;
		height: 32px;
	}

	.name {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		color: #636363;
	}

	.del {
		cursor: pointer;
		color: #e1504a;

		&:hover {
			text-decoration: underline;
		}
	}
}
</style>